import { Controller } from 'stimulus';
export default class extends Controller {
 connect() {

   var form = document.querySelector(".databind");
     form.addEventListener('change', function() {


      });

    $(window).bind('beforeunload', function(){

    });


    $(document).on("turbolinks:load", function () {
         //This is used to dynamically remove child fieldsets
                $('form').on('click', '.remove_fields', function(event) {
                  $(this).prev('input[type=hidden]').val('1');
                  $(this).closest('div').tooltip('hide');
                  var fieldset = $(this).closest('fieldset');
                  var form = document.querySelector(".databind");

                  submit_form(form);

                  fieldset.remove();

                  return event.preventDefault();
                });
         //This is used to dynamically add child fieldsets
                return $('form').on('click', '.add_fields', function(event) {
                  var regexp, time;
                  time = new Date().getTime();
                  regexp = new RegExp($(this).data('id'), 'g');
                  var parentFieldset = $(this).closest('fieldset');
                  var childFields = $("#"+parentFieldset.attr('id')+"-children").append($(this).data('fields').replace(regexp, time));

                  var newFields = document.getElementById(parentFieldset.attr('id')+"-children").lastElementChild;

                  var id = newFields.querySelector('input[name$="[id]"]');
                  id.setAttribute("value", time);

                  var form = document.querySelector(".databind");

                  submit_form_create(form);

                  return event.preventDefault();
                });
         });

    $(document).on("turbolinks:load", function () {

//This is used to dynamically remove child rows
       $('form').on('click', '.remove_rows', function(event) {
         $(this).prev('input[type=hidden]').val('1');
         $(this).closest('div').tooltip('hide');
         var fieldset = $(this).closest('tr');
         var form = document.querySelector(".databind");

         submit_form(form);

         var docFields = document.getElementById(fieldset.attr('id'));
         var fieldtags= ['input', 'textarea', 'select', 'button'];

         for (var tagi = 0; tagi < fieldtags.length; tagi++) {
             var fields= docFields.getElementsByTagName(fieldtags[tagi]);
             for (var fieldi= fields.length; fieldi-->0;) {

                 fields[fieldi].remove();
             }
         }

         fieldset.hide();

         return event.preventDefault();
       });
//This is used to dynamically add child rows
       return $('form').on('click', '.add_rows', function(event) {
         var regexp, time;
         time = new Date().getTime();
         regexp = new RegExp($(this).data('id'), 'g');
         var table = $(this).closest('table').append($(this).data('fields').replace(regexp, time));

         var newFields = document.getElementById(table.attr('id')).lastElementChild;

         var id = newFields.querySelector('input[name$="[id]"]');
         id.setAttribute("value", time);

         var form = document.querySelector(".databind");

         submit_form_create(form);

         return event.preventDefault();
       });


     });

 }

}

  function submit_form(form){

  }

  function submit_form_create(form){
    
  }
