import { Controller } from 'stimulus';
export default class extends Controller {
 connect() {
    //wait for document to be ready
  $(function() {
     var allColourInputs = $('input[type=color]');
     var allRadioInputs = $('input[type=radio]');

     var primaryColourInput = document.getElementById("theme_primaryColour");
     var primaryColour = primaryColourInput.value;

     var secondaryColourInput = document.getElementById("theme_secondaryColour");
     var secondaryColour = secondaryColourInput.value;

     var headerColourInput = document.getElementById("theme_headerColour");
     var headerColour = headerColourInput.value;

     var accentColourInput = document.getElementById("theme_accentColour");
     var accentColour = accentColourInput.value;

     var themeLogo = document.getElementById("theme_logo");
     var previewLogo = document.getElementById("bizXHeaderCompanyLogo_image");

     previewLogo.src = themeLogo.src;

     var backgroundImage = document.getElementById("background_image");
     var previewBackgroundImage = document.getElementById("__xmlview0--objectPage-headerContent");

     previewBackgroundImage.style.backgroundImage = "url('" + backgroundImage.src + "')";
     previewBackgroundImage.style.backgroundRepeat= "no-repeat";
     previewBackgroundImage.style.backgroundSize = "cover";
     

     let root = document.documentElement;
     root.style.setProperty('--primary-sf-color', primaryColour);
     root.style.setProperty('--secondary-sf-color', secondaryColour);
     root.style.setProperty('--header-sf-color', headerColour);
     root.style.setProperty('--accent-sf-color', accentColour);

   //if the primary colour is not dark, then set text colour to be dark
      if ( $("#theme_primaryIsDark_false").prop("checked") ) {
        root.style.setProperty('--body-txt-sf-color', "#000");
      } else {
        root.style.setProperty('--body-txt-sf-color', "#fff");
      }

    //if the secondary colour is not dark, then set text colour to be dark
       if ( $("#theme_secondaryIsDark_false").prop("checked") ) {
         root.style.setProperty('--tile-txt-sf-color', "#000");
       } else {
         root.style.setProperty('--tile-txt-sf-color', "#fff");
       }

     //if the header colour is not dark, then set text colour to be dark
        if ( $("#theme_headerIsDark_false").prop("checked") ) {
          root.style.setProperty('--header-txt-sf-color', "#000");
        } else {
          root.style.setProperty('--header-txt-sf-color', "#fff");
        }

     allColourInputs.on('change', function() {
        let root = document.documentElement;
        root.style.setProperty('--primary-sf-color', primaryColourInput.value);
        root.style.setProperty('--secondary-sf-color', secondaryColourInput.value);
        root.style.setProperty('--header-sf-color', headerColourInput.value);
        root.style.setProperty('--accent-sf-color', accentColourInput.value);
     });

     allRadioInputs.on('change', function() {
       let root = document.documentElement;

     //if the primary colour is not dark, then set text colour to be dark
        if ( $("#theme_primaryIsDark_false").prop("checked") ) {
          root.style.setProperty('--body-txt-sf-color', "#000");
        } else {
          root.style.setProperty('--body-txt-sf-color', "#fff");
        }

      //if the secondary colour is not dark, then set text colour to be dark
         if ( $("#theme_secondaryIsDark_false").prop("checked") ) {
           root.style.setProperty('--tile-txt-sf-color', "#000");
         } else {
           root.style.setProperty('--tile-txt-sf-color', "#fff");
         }

       //if the header colour is not dark, then set text colour to be dark
          if ( $("#theme_headerIsDark_false").prop("checked") ) {
            root.style.setProperty('--header-txt-sf-color', "#000");
          } else {
            root.style.setProperty('--header-txt-sf-color', "#fff");
          }
    });
});
 }

}
