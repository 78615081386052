import { Controller } from 'stimulus';
export default class extends Controller {
 connect() {


         //This is used to dynamically remove child fieldsets
          $('form').on('click', '.remove_fields', function(event) {
            $(this).closest('form').setChanged();
            $(this).attr('disabled', true);
            $(this).prev('input[type=hidden]').val('1');
            $(this).closest('div').tooltip('hide');
            var fieldset = $(this).closest('fieldset');
            fieldset.hide();
            $(this).removeAttr('disabled');
            return event.preventDefault();
          });
          //This is used to dynamically remove multiple child fieldsets at once
           $('.remove_selected_fields').on('click', function(event) {
             $(this).attr('disabled', true);
             $(this).closest('.container').find('.delete-flag').each(function () {
               if (this.checked) {
                 $(this).closest('form').setChanged();
                 $(this).next("input[type=hidden]").val('1');
                 var fieldset = $(this).closest('fieldset');
                 fieldset.hide();
                 var form = document.querySelector(".activation-form");
               }
             });
             $(this).attr('disabled', true);
             return event.preventDefault();
           });
         //This is used to dynamically add child fieldsets
          $('form').on('click', '.add_fields', function(event) {
            $(this).attr('disabled', true);
            var regexp, time;
            time = new Date().getTime();
            regexp = new RegExp($(this).data('id'), 'g');
            var parentFieldset = $(this).closest('fieldset');

            var childFields = $("#"+parentFieldset.attr('id')+"-children").append($(this).data('fields').replace(regexp, time));
            $(this).closest('form').setChanged();
            $(this).closest('form').trackChanges();
            $(this).removeAttr('disabled');
            return event.preventDefault();
          });


 }

}
