import { Controller } from 'stimulus';
export default class extends Controller {
 connect() {
     //load demo script
     var request = loadDemoScript();
 }

}

function loadDemoScript() {
  return $.ajax({
        type: "GET",
        url: window.location.href,
        processData: false,
        contentType: false,
        dataType: "script",
        success: function(result) {

        },
        error: function(result) {

        }
    });
}
