import { Controller } from 'stimulus';
export default class extends Controller {
 connect() {
          //This is used to dynamically remove multiple selected child fieldsets at once from outside of a form
           $('.remove_field_collection').on('mousedown', function(event) {
             $(this).attr('disabled', true);
             var response = confirm( $(this).data('confirm') || 'Are you sure?' );
             if (!response) { return; }

             $(this).closest('.container').find('.delete-flag').each(function () {
               if (this.checked) {
                 $(this).closest('form').setChanged();
                 $(this).next("input[type=hidden]").val('1');
                 var fieldset = $(this).closest('fieldset');
                 fieldset.hide();
               }
             });
             $(this).removeAttr('disabled');
             return event.preventDefault();
           });
         //This is used to dynamically add child fieldsets to a collection from outside of a form
          return $('.add_field_collection').on('mousedown', function(event) {
            $(this).attr('disabled', true);
            var regexp, time;
            time = new Date().getTime();
            regexp = new RegExp($(this).data('id'), 'g');
            var childFields = $('.form-body').append($(this).data('fields').replace(regexp, time));
            $(this).closest('form').setChanged();
            $(this).closest('form').trackChanges();
            $(this).removeAttr('disabled');
            //Added code to dynamically add additional levels if called from the structures form
            var level1_add_button = $('.level-1').last().find('.add_fields').first();
            if ( level1_add_button.length )
            {
                level1_add_button.trigger( 'click' );

                var level2_add_button = $('.level-1').last().find('.level-2').first().find('.add_fields').first();

                if ( level2_add_button.length )
                {
                    level2_add_button.trigger( 'click' );
                }
            }
            return event.preventDefault();
          });


 }

}
