import { Controller } from 'stimulus';
export default class extends Controller {
 connect() {
    $('[data-bs-toggle="tooltip"]').tooltip({
      trigger : 'hover'
    });
    $('[data-bs-toggle="tooltip"]').on('shown.bs.tooltip', function () {
        $('.tooltip').addClass('animated swing');
    });
    $('[data-bs-toggle="popover"]').popover();
    $('#LiveToast').toast('show');
 }
}
