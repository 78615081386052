import { Controller } from 'stimulus';
export default class extends Controller {
  connect() {
//Toggle tree view visibility
    $('form').on('click', '.tree_toggle', function(event) {
      var div = $(this).closest('div');
      var fieldset = $(this).closest('fieldset');
      var angle = -90;

      if ( $("#"+fieldset.attr('id')+"-children").is(":visible")) {
        div.attr("data-original-title", "Expand");
        rotateElement(div,angle);
      } else {
        div.attr("data-original-title", "Collapse");
        rotateElementReverse(div,angle);
      }

      $("#"+fieldset.attr('id')+"-children").toggle("slow", "swing");

      return event.preventDefault();
    });
  }
}

function rotateElement(element, angle){
  $({deg: 0}).animate({deg: angle}, {
    duration: 500,
    step: function(now) {
        // in the step-callback (that is fired each step of the animation),
        // you can use the `now` paramter which contains the current
        // animation-position (`0` up to `angle`)
        element.css({
            transform: 'rotate(' + now + 'deg)'
        });
    }
  });
}

function rotateElementReverse(element, angle){
  $({deg: angle}).animate({deg: 0}, {
    duration: 500,
    step: function(now) {
        // in the step-callback (that is fired each step of the animation),
        // you can use the `now` paramter which contains the current
        // animation-position (`0` up to `angle`)
        element.css({
            transform: 'rotate(' + now + 'deg)'
        });
    }
  });
}
