import { Controller } from 'stimulus';
export default class extends Controller {
 connect() {
   $('.activation-form input').attr('class', 'form-control-plaintext');
   $('.activation-form input').prop( "disabled", true );
   $('.activation-form select').attr('class', 'form-control-plaintext');
   $('.activation-form select').prop( "disabled", true );
   $('.activation-form a').remove();
   $('.activation-form button').remove();
 }
}
